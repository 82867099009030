import React, { useEffect, useState } from 'react';

import { currencyFormat } from 'helper/NumberFormat';
import { orderType } from 'constant/constant';
// material-ui
import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';

import { getOrders, confirmValuePackPayment, confirmBookingPayment, confirmItemOrderPayment, rejectPayment } from 'services/orderServices';

import moment from 'moment';

import TableComponent from "components/TopCourtComponents/Table/TableComponent";
import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import OrderFilter from './orderFilter';
import { useNavigate } from 'react-router';
import CreateOrder from './createOrder';
import { IconCheck, IconCross, IconX } from '@tabler/icons';
import { toastMessage } from 'components/Toast/Toast';
import { TCInput } from 'components/TopCourtComponents/Input/TopCourtInput';

const dialogInit = {
  open: false,
  data: {}
}

const initFilter = {
  startCreatedDate: null,
  endCreatedDate: null,
  createdDateVariable: { label: "<>", value: "ib" },
  bookingId: "",
  user: null,
  court: null,
  orderStatus: null,
  startNominal: null,
  endNominal: null,
  type: null,
  status: null,
  nominalVariable: { label: "<>", value: "ib" },
  productName: null,
  page: 0
}

const orderStatusMap = (data) => {
  if (data.orderStatus) {
      let message = "Awaiting Payment"
      let { orderStatus, orderType } = data;
      if (orderStatus[0].date != null) { message = "Awaiting Payment" }
      if (orderStatus[1].date != null) { message = "Paid" }
      // if (orderStatus[1].date != null && orderType == 'sell') { message = "Counter Pick Up" }
      if (orderStatus[1].date != null && orderType == 'rent' && data.lastStatus == "rent") { message = "Rent" }
      if (orderStatus[2].date != null) { message = "Completed" }
      if (orderStatus[3].date != null) { message = "Payment Failed" }
      return message
  } else return 'Awaiting Payment'
}

function Orders({

}) {

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalData, setTotalData] = useState(0)
  const [openDialog, setOpenDialog] = useState(dialogInit)
  const [openCreateOrder, setOpenCreateOrder] = useState({
    open: false
  })

  const [filter, setFilter] = useState(initFilter)

  const navigate = useNavigate()

  useEffect(() => {
    loadOrders()
  }, [filter])

  const loadOrders = async () => {
    setLoading(true)
    let { data, error } = await getOrders({
      startDate: filter.startCreatedDate,
      endDate: filter.endCreatedDate,
      dateVariable: filter.createdDateVariable ? filter.createdDateVariable.value : '',
      bookingId: filter.bookingId? filter.bookingId : '',
      player: filter.user? filter.user.value : '',
      type: filter.type? filter.type.value : '',
      orderStatus: filter.status? filter.status.value: '',
      page: filter.page
    })
    if (error) { console.log(error) }
    if (data) { 
      setRows(data.orders)
      setTotalData(data.totalData)
    }
    setLoading(false)
  }

  const confirmPayment = async (adminDiscount) => {
    if (openDialog.data.orderType === "value_pack") {
      let { data, error } = await confirmValuePackPayment(openDialog.data._id, adminDiscount)
      if (error) { console.log(error); toastMessage(error.message ? error.message : 'Error', {
          toastId: "player-success",
          type: "error"
      });}
      if (data) {
        console.log(data); setOpenDialog(prev => ({
          ...prev,
          type: null,
          open: false
        })); 
        setOpenDialog(dialogInit)
        toastMessage('Payment Confirmed', {
          toastId: "payment-success",
          type: "success",
        });
        loadOrders()  
      }
    }
    if (openDialog.data.orderType === "booking") {
      let { data, error } = await confirmBookingPayment(openDialog.data._id, adminDiscount)
      if (error) { toastMessage(error.message ? error.message : 'Error', {
          toastId: "player-success",
          type: "error"
      }); }
      if (data) {
        console.log(data); setOpenDialog(prev => ({
          ...prev,
          type: null,
          open: false
        })); 
        setOpenDialog(dialogInit)
        toastMessage('Payment Confirmed', {
          toastId: "payment-success",
          type: "success"
        });
        loadOrders()  
      }
    }
    if (openDialog.data.orderType === "rent" || openDialog.data.orderType === "sell" 
    || openDialog.data.orderType === "service" || openDialog.data.orderType === "class") {
      let { data, error } = await confirmItemOrderPayment(openDialog.data._id, adminDiscount)
      if (error) { toastMessage(error.message ? error.message : 'Error', {
          toastId: "player-success",
          type: "error"
      }); }
      if (data) {
        console.log(data); setOpenDialog(prev => ({
          ...prev,
          type: null,
          open: false
        })); 
        setOpenDialog(dialogInit)
        toastMessage('Payment Confirmed', {
          toastId: "payment-success",
          type: "success"
        });
        loadOrders()  
      }
    }
  }

  const rejectOrder = async () => {
    let { data, error } = await rejectPayment(openDialog.data._id)
    if (error) { console.log('here is error', error); toastMessage(error.message ? error.message : 'Error', {
        toastId: "player-success",
        type: "error"
    }); }
    if (data) {
      setOpenDialog(dialogInit)
      toastMessage('Order Rejected', {
        toastId: "order-rejected",
        type: "success"
      });
      loadOrders()  
    }
  }

  const valueCustomator = (row) => {
    return ({
      createdDate: <TCLabel>{moment(row.createdDate).format("YYYY/MM/DD HH:mm:ss")}</TCLabel>,
      bookingId: <Grid><a href={`/orders/${row._id}`} style={{
        color: 'black'
      }}>{row.bookingId}</a></Grid>,
      user: <Grid><a href={`/users/detail/${row.user ? row.user._id : null}`} style={{
        color: 'black'
      }}>{row.user ? row.user.name : row.createdBy ? `${row.createdBy.name} STC` : ''}</a></Grid>,
      type: <TCLabel>{orderType(row.orderType)}</TCLabel>,
      total: <TCLabel>{currencyFormat(row.total)}</TCLabel>,
      action: <div>
        {
          !row.isPaid && row.lastStatus !== 'failed' ? 
          <Grid container spacing={1}>
            <Grid item>
              <Button variant='contained' style={{
                backgroundColor: '#E35480'
              }}  onClick={() => {
                setOpenDialog(prev => ({
                  ...prev,
                  type: "reject",
                  open: true,
                  data: row
                }))
              }}><IconX size={15}/></Button> 
            </Grid>
            <Grid item>
              <Button variant="contained" 
              sx={{
                backgroundColor: '#0198E1'
              }}
              onClick={() => {
                setOpenDialog(prev => ({
                  ...prev,
                  type: "accept",
                  open: true,
                  data: row
                }))
              }}><IconCheck size={15}/></Button> 
            </Grid>
          </Grid>
          : row.lastStatus == "failed" ? "Payment Failed" 
          : orderStatusMap(row)
        }
      </div>
    })
  }

  const onChangeFilter = (e) => {
    setFilter(prev => ({
      ...prev,
      ...e,
      page: 0
    }))
  }

  const onChangePage = (e) => {
    setFilter(prev => ({
        ...prev,
        ...e
    }))
  }

  const clearFilter = () => {
    setFilter(initFilter)
  }

  return (
    <>
      <Button variant='contained' sx={{ mb: 2 }}
        onClick={() => {
          navigate('/orders/create-order')
        }}
      >Create Order</Button>
      <OrderFilter filter={filter} onChangeFilter={onChangeFilter} clearFilter={clearFilter} />
      <TableComponent
        columns={[
          { label: "Created Date" },
          { label: "Booking ID" },
          { label: "User" },
          { label: "Type" },
          { label: "Total" },
          { label: "Action" },
        ]}
        loading={loading}
        valueCustomator={valueCustomator}
        totalData={totalData}
        rows={rows}
        page={filter.page}
        pageChange={(e) => {
            onChangePage({page: e})
        }}
      />
      <Dialog open={openDialog.open || openCreateOrder.open}>
        {
          openDialog.open && <ConfirmationPayment
            data={openDialog.data}
            confirmPayment={openDialog.type == "accept" ? (e) => {
              confirmPayment(e)
            } : null} rejectOrder={openDialog.type == "reject" ? (e) => {
              rejectOrder()
            } : null} setOpenDialog={(e) => {
              setOpenDialog(e)
            }}
          />
        }
        {
          openCreateOrder.open && <CreateOrder />
        }
      </Dialog>
    </>
  )
}

function ConfirmationPayment({
  data, confirmPayment, rejectOrder, setOpenDialog
}) {

  const [ adminDiscount, setAdminDiscount ] = useState("0")

  return (
    <Card>
      <Grid sx={{
        p: 3
      }}>
        <Grid>
        {/* 
          Check tipe booking
         */}
         {
            data.orderType == "booking" &&
            <Grid>
              <TCLabel bold style={{fontSize: 16}}>Booking</TCLabel>
              <Grid pt={1} container direction={'row'}>
                {/* {
                    data.hours.map((h, index) => {
                        return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                            ${index < (v.hours.length - 1) ? " | " : ""}`}</TCLabel>
                    })
                }     */}
              </Grid>
            </Grid>
         }
         {
            data.orderType == "rent" &&
            <Grid>
              <TCLabel bold>Rent</TCLabel>
              <Grid pt={1} container direction={'row'}>
                {/* {
                    data.hours.map((h, index) => {
                        return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                            ${index < (v.hours.length - 1) ? " | " : ""}`}</TCLabel>
                    })
                }     */}
              </Grid>
            </Grid>
         }
         {
            data.orderType == "service" &&
            <Grid>
              <TCLabel bold>Service</TCLabel>
              <Grid pt={1} container direction={'row'}>
                {/* {
                    data.hours.map((h, index) => {
                        return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                            ${index < (v.hours.length - 1) ? " | " : ""}`}</TCLabel>
                    })
                }     */}
              </Grid>
            </Grid>
         }
         {
            data.orderType == "sell" &&
            <Grid>
              <TCLabel bold>Buy</TCLabel>
              <Grid pt={1} container direction={'row'}>
                {/* {
                    data.hours.map((h, index) => {
                        return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                            ${index < (v.hours.length - 1) ? " | " : ""}`}</TCLabel>
                    })
                }     */}
              </Grid>
            </Grid>
        }
        </Grid>
        <Grid pb={1}>
          <TCLabel>Are you sure want to {confirmPayment ? 'confirm' : 'reject'} <span style={{ fontWeight: 'bold' }}>{data.bookingId}</span> payment ?</TCLabel>
        </Grid>
        <Grid container pb={1} >
          <Grid item spacing={2} xs={12} md={6}>
            {/* <Grid item>{currencyFormat(data.subtotal)}</Grid> */}
            <Grid>
              <TCLabel >Total Payment : </TCLabel>
            </Grid>
            <Grid item container style={{
              fontWeight: 'bold',
              minHeight: 40,
              alignItems: 'center'
            }}>{currencyFormat(data.total)}</Grid>
            {/* <Grid item>{"Hello World"}</Grid> */}
          </Grid>
          { confirmPayment && 
            <Grid item spacing={2} xs={12} md={6}>
              <Grid>
                <TCLabel>Total Discount : </TCLabel>
              </Grid>
              <TCInput
                value={adminDiscount}
                onChange={(e) => {
                  let value = e.currentTarget.value
                  if (!value) {
                    setAdminDiscount(value)
                    return
                  }
                  if (isNaN(value * 1)) {
                    value = adminDiscount
                  } else {
                    value = (value *1)
                    if (value > data.total) {
                      value = data.total
                    }
                    if (value <= 0) {
                      value = "0"
                    }
                  }
                  setAdminDiscount(value)
                }}
              />
            </Grid>
          }
        </Grid>
        {
          confirmPayment &&
          <Grid>
            <TCLabel style={{ fontSize: 10 }}>* Maximum discount value is <span style={{fontWeight: 'bold'}}>{currencyFormat(data.total)}</span></TCLabel>
            <TCLabel style={{ fontSize: 10 }}>* Please make sure we already receive the money</TCLabel>
          </Grid>
        }
        <Grid container xs={12} spacing={1} pt={1} justifyContent={"right"}>
          <Grid item>
            <Button onClick={() => { setOpenDialog(prev => ({
              ...prev,
              type: null,
              open: false })) }}>Cancel</Button>
          </Grid>
          {/* <Grid item>
            <Button color='error' variant='contained' onClick={() => { rejectOrder() }} >Reject</Button>
          </Grid> */}
          <Grid item>
            <Button variant='contained' style={{
              backgroundColor: confirmPayment ? 
                !adminDiscount ? '#c8ced3' : '#0198E1' : '#E35480'
            }} 
            disabled={(confirmPayment && !adminDiscount)}
            onClick={() => { 
              if (confirmPayment) {
                confirmPayment(adminDiscount)
              }
              if (rejectOrder) {
                rejectOrder()
              }
             }} >{confirmPayment ? "Confirm" : "Reject"}</Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default Orders;
